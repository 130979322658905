
import { Component, Watch } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import { getComponent } from '@/utils/helpers';
import { clearSession } from '@/lib/auth';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import tasqsListModule from '@/store/modules/tasqsListModule';
import workflowModule from '../../store/modules/workflowModule';

@Component({
  components: {
    NotificationSidebarWidget: () => getComponent('notifications/NotificationSidebarWidget'),
  },
})
export default class AppSidebar extends mixins(GenericMixin) {
  expandedLink: any = {};

  isHovering = false;

  isComponentMounted = false;

  hoverLinkName = ''

  appVersion = '4.1.3';

  created() {
    this.$nextTick(() => {
      this.isComponentMounted = true;
    });
  }

  userInitial = '';

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  };

  links = [
    {
      name: 'Tasqs',
      to: 'Tasqs',
      displayName: 'Tasq List',
      imgName: 'format-list.svg',
      children: [
        {
          name: 'Tasqs',
          to: 'Tasqs',
        },
        {
          name: 'Pads',
          to: 'Pads',
        },
      ],
    },
    // {
    //   name: 'Schedule',
    //   to: 'WorkTicketCalendar',
    //   displayName: 'Schedule',
    //   imgName: 'calendar.svg',
    //   children: [],
    // },
    {
      name: 'ControlPanel',
      to: 'ControlPanel',
      displayName: 'Control Panel',
      imgName: 'control-panel.svg',
      children: [],
    },
  ];

  isSidebarOpen = true;

  get userInitals() {
    if (workflowModule.user.firstName && workflowModule.user.lastName) {
      return getNameByEmail(workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0));
    }

    return '';
  }

  get currentUserName() {
    return `${workflowModule.user.firstName} ${workflowModule.user.lastName}`;
  }

  get currentUserEmail() {
    return workflowModule.user.email;
  }

  openChildRoutesForLink(link) {
    return (this.expandedLink.to === link.to || this.isActiveLink(link)) && link.children && this.isComponentMounted;
  }

  handleSidebarItemClick(link) {
    if (this.expandedLink && link.to === this.expandedLink.to) {
      this.$router.push({
        name: link.to,
      });
      return;
    }
    if (link.children?.length) {
      this.expandedLink = link;
      this.$router.push(link.children[0].to);
      return;
    }
    this.$router.push({
      name: link.to,
    });
  }

  isActiveLink(link) {
    return link.to === this.$route.name || this.$route.meta!.parent === link.name;
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  @Watch('$route.name', { immediate: true })
  async onTasqRouteIdChange() {
    tasqsListModule.closeNotificationList();
  }
}
